import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';

import clsx from 'clsx';

import { IonModal } from '@ionic/react';

type IModalProps = {
  children?: ReactNode;
  modalId?: string;
};

export type IModalContextValues = {
  closeModal: () => void;
  closeSecondaryModal: () => void;
  opened?: boolean;
  setModalProps: React.Dispatch<React.SetStateAction<IModalProps>>;
  setOpened: (opened: boolean) => void;
  setSecondaryModalProps: React.Dispatch<React.SetStateAction<IModalProps>>;
};

export const ModalContext = createContext<IModalContextValues>({
  closeModal: () => {
    //
  },
  closeSecondaryModal: () => {
    //
  },
  opened: false,
  setModalProps: () => {
    //
  },
  setOpened: () => {
    //
  },
  setSecondaryModalProps: () => {
    //
  },
});

export function ModalContextProvider(props: { children?: ReactNode }) {
  const [opened, setOpened] = useState(false);
  const [modalProps, setModalProps] = useState<IModalProps>({
    children: undefined,
    modalId: undefined,
  });

  const [secondaryOpened, setSecondaryOpened] = useState(false);
  const [secondaryModalProps, setSecondaryModalProps] = useState<IModalProps>({
    children: undefined,
    modalId: undefined,
  });

  useEffect(() => {
    setOpened(!!modalProps.children);
  }, [modalProps.children]);

  useEffect(() => {
    setSecondaryOpened(!!secondaryModalProps.children);
  }, [secondaryModalProps.children]);

  return (
    <ModalContext.Provider
      value={{
        closeModal: () => {
          setOpened(false);
          setTimeout(() => setModalProps({}), 100);
        },
        closeSecondaryModal: () => {
          setSecondaryOpened(false);
          setTimeout(() => setSecondaryModalProps({}), 100);
        },
        opened,
        setModalProps,
        setOpened,
        setSecondaryModalProps,
      }}
    >
      {props.children}

      <IonModal
        animated
        breakpoints={[0, 0.5, 1]}
        className={clsx(modalProps.modalId || 'context-modal')}
        initialBreakpoint={1}
        isOpen={opened}
        keyboardClose
        onDidDismiss={() => setOpened(false)}
      >
        {modalProps.children}
      </IonModal>

      <IonModal
        animated
        breakpoints={[0, 0.5, 1]}
        className={clsx(secondaryModalProps.modalId || 'context-modal')}
        initialBreakpoint={1}
        isOpen={secondaryOpened}
        keyboardClose
        onDidDismiss={() => setSecondaryOpened(false)}
      >
        {secondaryModalProps.children}
      </IonModal>
    </ModalContext.Provider>
  );
}

export const useModalContext = () => {
  const modalContext = useContext(ModalContext);

  if (typeof modalContext === 'undefined') {
    throw new Error('useModalContext must be used within a ModalContextProvider');
  }

  return modalContext;
};
